<template>
  <div>
    <Divider dashed style="margin: 5px 0;">
      <span class="divider-text">设置通知内容</span>
    </Divider>
    <Form ref="formValidate" :model="noticeBean" :rules="ruleValidate" >
      <FormItem label="通知标题" prop="title">
        <Input v-model="noticeBean.title" maxlength="50" size="small" placeholder="请输入通知内容的标题..."  />
      </FormItem>
      <FormItem label="通知内容描述" prop="content">
        <Input v-model="noticeBean.content" type="textarea"  class="p-t-5"   maxlength="500" :autosize="{minRows: 4,maxRows: 6}" placeholder="请输入通知内容描述，不能少于10个字符"/>
      </FormItem>
      <p class="remark p-b-5 p-t-5">上传通知附件</p>
      <FormItem>
          <Upload multiple type="drag" :before-upload="handleBeforeUploadFile" action="">
              <div >
                  <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                  <p>点击或拖拽文件到这里进行上传</p>
              </div>
          </Upload>
          <Row v-show="fileList.length">
              <i-col span="14"><b>文件名称</b></i-col>
              <i-col span="5"><b>文件大小</b></i-col>
              <i-col span="5"><b>操作</b></i-col>
          </Row>
          <hr v-show="fileList.length">
          <Row v-for="(file,fileIndex) in fileList" :key="fileIndex"  v-show="fileList.length">
              <i-col span="14">{{file.name}}</i-col>
              <i-col span="5">{{(file.size / 1024).toFixed(2)}} KB</i-col>
              <i-col span="5"><a href="javascript:;" class="text-red" @click="handleRemove(file)">删除</a></i-col>
              <i-col span="24"><hr></i-col>
          </Row>

          <template v-if="editFileList.length>0">
            <div class="workplatform-title m-t-20 m-b-10">已上传文件清单</div>
            <Row>
                <i-col span="14"><b>文件名称</b></i-col>
                <i-col span="5"><b>文件大小</b></i-col>
                <i-col span="5"><b>操作</b></i-col>
            </Row>
            <hr>
            <Row v-for="(file,fileIndex) in editFileList" :key="fileIndex">
                <i-col span="14">{{file.fileName}}</i-col>
                <i-col span="5">{{file.lenth}} KB</i-col>
                <i-col span="5"><a href="javascript:;" class="text-red" @click="handleRemoveOldFile(file)">删除</a></i-col>
                <i-col span="24"><hr></i-col>
            </Row>
          </template>

      </FormItem>

      <Row class="m-b-5">
          <i-col span="24">
            <new-node
                :id="null"
                :editable="false"
                :can-delete="false"
                :name="'设置通知人员'"
                :users="sendPerson"
                :publisherId="publisherId"
                :companyId="companyId"
                :needGetChild="true"
                @removeUser="handleGroupSendUserRemove"
                @addUser="handleAddSendUser"
                ></new-node>
          </i-col>
      </Row>
      <FormItem class="text-right">
          <Button type="success" size="small" @click="handleSubmit()">发起通知</Button>
      </FormItem>
    </Form>

    <Modal
      v-model="beginUpload"
      width="400"
      :styles="{top: '250px'}"
      :mask-closable="false"
      :closable="false"
      footer-hide
    >
      <h3 class="text-center">通知消息发送中..</h3>
      <Progress :percent="percentage" :stroke-width="20" status="active" text-inside></Progress>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { checkNotice, getNoticeFiles, getNoticeUsers } from '@/api/sys/notice'

export default {
  components: {
    NewNode: () => import('@/components/setting/workflow/NewNode')
  },
  data () {
    return {
      noticeBean: {
        title: '',
        content: ''
      },
      ruleValidate: {
        title: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        content: [
          { required: true, message: ' ', trigger: 'blur' },
          { type: 'string', min: 10, message: ' ', trigger: 'blur' }
        ]
      },

      fileList: [],
      editFileList: [], // 旧版的编辑文件
      percentage: 0, // 文件上传进度
      beginUpload: false, // 开始执行上传
      sendPerson: [] // 发送人员清单
    }
  },
  methods: {
    initPageData () {
      const queryModel = {
        noticeId: this.detailId
      }

      checkNotice(queryModel).then(res => {
        this.noticeBean.title = res.title
        this.noticeBean.content = res.content
      })

      getNoticeFiles(queryModel).then(res => {
        this.editFileList = res
      })

      getNoticeUsers(queryModel).then(res => {
        res.forEach(element => {
          this.sendPerson.push({
            id: element.userId,
            name: element.userName
          })
        })
      })
    },
    handleBeforeUploadFile (file) {
      this.fileList.push(file)
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleRemoveOldFile (file) {
      this.editFileList.splice(this.editFileList.indexOf(file), 1)
    },
    // 增加节点用户
    handleAddSendUser (nodeId, user) {
      this.sendPerson.push(user)
    },
    // 移除节点用户
    handleGroupSendUserRemove (nodeId, userIndex) {
      this.sendPerson.splice(userIndex, 1)
    },
    handleSubmit () {
      const _self = this
      if (_self.sendPerson.length === 0) {
        this.$Notice.info({ desc: '请先配置通知人员！' })
        return
      }

      _self.$refs.formValidate.validate((valid) => {
        if (valid) {
          _self.beginUpload = true
          _self.percentage = 0

          // 开始批量执行上传操作
          const uploadData = new FormData()
          uploadData.append('title', _self.noticeBean.title)
          uploadData.append('content', _self.noticeBean.content)
          uploadData.append('userIds', JSON.stringify(_self.sendPerson.map(x => x.id)))
          uploadData.append('oldFiles', JSON.stringify(_self.editFileList))

          _self.fileList.forEach(function (file, index) {
            uploadData.append('files', file)
          })

          axios.post(process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/notice/sendnotice',
            uploadData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'ooh-auth': getStorage('ooh-token')
              },
              withCredentials: true,
              onUploadProgress (progress) { // 处理上传文件进度条数据
                _self.percentage = Math.round(progress.loaded / progress.total * 100)
              }
            }
          ).then(res => {
            _self.beginUpload = false
            if (res && res.data && !res.data.errcode) {
              _self.$Notice.success({ desc: '通知消息发送成功！' })
              _self.$refs.formValidate.resetFields()
              _self.fileList = []
              _self.sendPerson = []
              if (_self.detailId) {
                _self.$store.commit('set_approvalCreate_detailId', null)
              }

              _self.$store.commit('set_approvalCreate_update', new Date())
            } else {
              _self.$Notice.error({ desc: res.data.errmsg })
            }
          })
        }
      })
    }
  },
  computed: {
    detailId () {
      return this.$store.state.approvalCreate.detailId
    },
    companyId () {
      return this.$store.state.approvalCreate.companyId
    },
    publisherId () {
      return this.$store.state.approvalCreate.publisherId
    }
  },
  watch: {
    detailId (val) {
      this.fileList = []
      this.sendPerson = []
      this.editFileList = []

      if (this.detailId) {
        this.initPageData()
      } else {
        this.$refs.formValidate.resetFields()
      }
    }
  }
}
</script>
