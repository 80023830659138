import request from '@/utils/requestV2'
const qs = require('qs')

// 获取通知消息集合
export function getNoticePage (data) {
  return request({
    url: '/ooh-sys/v1/notice/getpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查看消息
export function checkNotice (data) {
  return request({
    url: '/ooh-sys/v1/notice/checknotice',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取文件
export function getNoticeFiles (data) {
  return request({
    url: '/ooh-sys/v1/notice/getfile',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取消息接收方
export function getNoticeUsers (data) {
  return request({
    url: '/ooh-sys/v1/notice/getuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
